/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

https://polaris.corp.amazon.com/getting_started/development/integration/
************************************************************************/
.custom-home__header {
  background-color: #232f3e;
}

.custom-home__header-title {
  color: #f5902a;
}

.custom-home__category,
.custom-home__header-sub-title {
  color: #d5dbdb;
}

@media (min-width: 992px) {
  .custom-home__sidebar {
    margin-top: -6rem;
  }
}

.custom-home__header-title > * {
  max-width: 200rem;
}

.custom-home-image__placeholder:before {
  content: "X";
  display: block;
  background-color: #f2f3f3;
  color: #ffffff;
  text-align: center;
  font-size: 40rem;
  line-height: 40rem;
}

ul.custom-list-separator {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.custom-list-separator li {
  border-top: 1px solid #eaeded;
  padding: 0.8rem 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

ul.custom-list-separator li:first-child {
  padding-top: 0;
  border-top: none;
}

ul.custom-list-separator li:last-child {
  padding-bottom: 0;
}
