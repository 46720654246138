/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

https://polaris.corp.amazon.com/getting_started/development/integration/
************************************************************************/

.intro-logo {
  height: 9rem;
  padding-top: 1rem;
}

.intro-screenshot {
  height: 30rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.intro-code-screenshot {
  padding-right: 2rem;
}

.header-badge {
  background-color: #b49116;
  padding: 2px;
  border-radius: 10%;
  width: 3em;
}

code {
  background-color: #f2f3f3;
  padding: 0 0.5rem;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}


/* round photo
.header-badge {
  background-color: #b49116;
  border-radius: 100%;
  margin: 10px;
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  -moz-box-shadow: 3px 3px 3px #000607;
  -webkit-box-shadow: 3px 3px 3px #000000;
  box-shadow: 3px 3px 3px #000000;
  -moz-border-radius:190px;
  -webkit-border-radius:190px;
}
 */